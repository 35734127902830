// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'bikes',
    path: '/dashboard/bike',
    icon: icon('ic_bike'),
  },
  {
    title: 'products',
    path: '/dashboard/product/',
    icon: icon('ic_pedal'),
  },
  {
    title: '-- brake pads',
    path: '/dashboard/product/42',
  },
  {
    title: '-- brake calipers',
    path: '/dashboard/product/37',
  },
  {
    title: '-- brake levers',
    path: '/dashboard/product/32',
  },
  {
    title: '-- brake rotors',
    path: '/dashboard/product/45',
  },
  {
    title: '-- front wheels',
    path: '/dashboard/product/47',
  },
  {
    title: '-- rear wheels',
    path: '/dashboard/product/48',
  },
  {
    title: 'logs',
    path: '/dashboard/logs',
    icon: icon('outline-document-scanner'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
