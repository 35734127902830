import { resources } from '../config';

export const getLogs = async (logGuid, logSku) => {

    const headers = new Headers();

    headers.append('BM-Subscription-Key', resources.apiBikes.key);

    const options = {
        method: 'GET',
        headers,
    };

    // create a string that is empty if logGuid is null or undefined, otherwise the string is logGuid and logSku
    const endpointParameters = logGuid ? `${logGuid}/${logSku}` : '';

    console.log(`${resources.apiBikes.endpoint}/admin/GetLogs/${endpointParameters}`);
    return fetch(`${resources.apiBikes.endpoint}/admin/GetLogs/${endpointParameters}`, options)
        .then((response) => response.json())
        .catch((error) => console.log(error));


};
