export const resources = {
    apiBikes: {
        // endpoint: 'https://bikematrix-20230522-1515-dev-api.azurewebsites.net',
        endpoint: `${process.env.REACT_APP_API_URL}`,
        key: `${process.env.REACT_APP_API_KEY}`,
    },


};

